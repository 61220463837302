import { fluxOfIdeasConstants, ideaGeneralEvaluationConstants } from '../constants';

const initialState = {
  ideaInFlux: {},
  managerObservation: '',
  evaluationInFluxIdea: [],
  loading: false,
  loadingUpdate: false
};

export function fluxIdeas(state = initialState, action) {
  switch (action.type) {
    case fluxOfIdeasConstants.SET_MANAGER_OBSERVATION:
      return {
        ...state,
        managerObservation: action.payload.value
      }

    case fluxOfIdeasConstants.CLEAR_FLUX_OF_IDEAS:
      return {
        ...state,
        managerObservation: ''
      }

    case fluxOfIdeasConstants.SET_EVALUATION_RATES_IN_FLUX_IDEA:
      return {
        ...state,
        // evaluationInFluxIdea: state.evaluationInFluxIdea.map((item, index) => {
        //   if (index === action.payload.rate) {
        //     return {
        //       ...item,
        //       rate: action.payload.rate
        //     }
        //   }
        // }),
      }

    case fluxOfIdeasConstants.GET_IDEA_IN_FLUX_REQUEST:
    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      }

    case fluxOfIdeasConstants.GET_IDEA_IN_FLUX_SUCCESS:
      return {
        ...state,
        ideaInFlux: action.payload,
        loading: false
      }

    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_SUCCESS:
      return {
        ...state,
        evaluationInFluxIdea: action.payload.map(item => {
          return {
            personalizedEvaluation: item,
            rate: 1
          }
        }),
        loading: false
      }

    case fluxOfIdeasConstants.GET_IDEA_IN_FLUX_FAILURE:
    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_FAILURE:
      return {
        ...state,
        loading: false
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_REQUEST:
      return {
        ...state,
        loadingUpdate: true
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_SUCCESS:
      return {
        ...state,
        loadingUpdate: false
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_FAILURE:
      return {
        ...state,
        loadingUpdate: false
      }

    default: {
      return state;
    }
  }
}
