import React from 'react';
import { Container, makeStyles, Button, Grid, Card, Box } from '@material-ui/core';
import {
  BackNavAdmin,
  ChallengeCard,
  ChallengeFilters,
} from '../../components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { challengeActions } from '../../actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { SkeletonChallengeCard } from '../../components/Shared/SkeletonChallengeCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  btnNewChallenge: {
    float: 'right',
    padding: theme.spacing(1, 2),
  },
}));

function ChallengePage() {
  const classes = useStyles();
  const {
    typeProfile: { type },
  } = useSelector((state) => state.user);
  const {
    challenges,
    paging: { page, sort, filter },
    loading,
  } = useSelector((state) => state.challenge);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(challengeActions.clear());
    dispatch(challengeActions.getChallenges(1, sort, filter, 12));
  }, []);

  useEffect(() => {
    if (!loading) {
      dispatch(challengeActions.getChallenges(1, sort, filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function searchChallenge(query) {
    filter[0].value = query;
    dispatch(challengeActions.getChallenges(1, sort, filter, 12));
  }

  function changeStatus(status) {
    filter[1].value = status;
    dispatch(challengeActions.getChallenges(1, sort, filter));
  }

  function loadMoreChallenges() {
    if (!loading) {
      dispatch(challengeActions.getMoreChallenges(page, sort, filter, 12));
    }
  }


  return (
    <div>
      <BackNavAdmin title={!!challenges.length ? t('desafios') : t('carregando')} pathname="/">
        {type === 'admin' && (
          <Button
            className={classes.btnNewChallenge}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<AddCircleIcon />}
            component={Link}
            to="/challenge/new"
          >
            {t('novo_desafio')}
          </Button>
        )}
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <ChallengeFilters
          queryFilter={searchChallenge}
          classification={filter[1].value}
          changeStatus={changeStatus}
        />

        <Grid xs={12} item>
          {!!challenges.length ? (
            <InfiniteScroll
              dataLength={challenges.length}
              next={loadMoreChallenges}
              hasMore={true}
              scrollThreshold={'90%'}
              style={{ overflow: 'hidden', paddingBottom: 20 }}
            >
              <Grid container spacing={2}>
                {challenges.map((challenge) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={challenge._id}>
                    <ChallengeCard challenge={challenge} />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          ) : <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SkeletonChallengeCard />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SkeletonChallengeCard />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SkeletonChallengeCard />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SkeletonChallengeCard />
            </Grid>

          </Grid>}
        </Grid>
      </Container>
    </div>
  );
}

export { ChallengePage };
