import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Search,
  UsersPointsTable,
  SelectedUser,
  UserPointsFilterMenu,
} from '../../components';
import { history } from '../../helpers';
import { SkeletonTable } from '../../components/Skeletons';
import { userFunctionActions, walletActions } from '../../actions';

const useStyles = makeStyles({
  container: {
    marginTop: 32
  }
});

export const UserPoints = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { imagineLocals } = useSelector(state => state.domains);
  const { selectedUser, loadingUser } = useSelector(state => state.userFunction);
  const {
    loading,
    paging: { quantityPerPage, filter, sort, query },
  } = useSelector((state) => state.wallet);
  
  useEffect(() => {
    if (!selectedUser && !loadingUser) {
      if (id) {
        dispatch(userFunctionActions.loadPage(id, imagineLocals));
      } else {
        history.push("/users");
      }
    } else {
      if (selectedUser) {
        dispatch(walletActions.getUserPoints(selectedUser.id));
        dispatch(walletActions.getWallets(selectedUser.id, 1, quantityPerPage, filter, sort, query))
      }
    }
  }, [selectedUser, id])

  useEffect(() => {
    return () => dispatch(walletActions.clearState())
  }, [dispatch])

  function searchWallet(q) {
    dispatch(walletActions.setQuery(q));
    dispatch(walletActions.getWallets(selectedUser.id, 1, quantityPerPage, filter, sort, q));
  }

  return (
    <Grid container spacing={3}  >
      <Grid item xs={12}>
        { selectedUser && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SelectedUser showPoints={true} />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={7}>
                  <UserPointsFilterMenu />
                </Grid>
                <Grid item xs={5}>
                  <Search
                    disabled={loading}
                    placeholder={t('pesquisar_por_ideia_ou_acao')}
                    query={searchWallet}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loading ? <SkeletonTable cols={4} /> : <UsersPointsTable />}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}