export const curationConstants = {
  GET_IDEAS_CURATION_REQUEST: 'GET_IDEAS_CURATION_REQUEST',
  GET_IDEAS_CURATION_SUCCESS: 'GET_IDEAS_CURATION_SUCCESS',
  GET_IDEAS_CURATION_FAILURE: 'GET_IDEAS_CURATION_FAILURE',
  GET_IDEAS_CURATION_MOBILE_SUCCESS: 'GET_IDEAS_CURATION_MOBILE_SUCCESS',
  GET_IDEAS_CURATION_MOBILE_REQUEST: 'GET_IDEAS_CURATION_MOBILE_REQUEST',
  SET_CURATION_FILTERS: 'SET_CURATION_FILTERS',
  CHANGE_CURATION_CLASSIFICATION: "CHANGE_CURATION_CLASSIFICATION",
  SET_STATUS_CODE_TO_FILTER: 'SET_STATUS_CODE_TO_FILTER',
  TOGGLE_FILTER: 'TOGGLE_FILTER',
  CLEAR_CURATION_FILTERS: 'CLEAR_CURATION_FILTERS',
  CLEAR_FILTERS_NOT_STATUS: 'CLEAR_FILTERS_NOT_STATUS',

  GET_ALL_IDEAS_PORTFOLIO_REQUEST: 'GET_ALL_IDEAS_PORTFOLIO_REQUEST',
  GET_ALL_IDEAS_PORTFOLIO_SUCCESS: 'GET_ALL_IDEAS_PORTFOLIO_SUCCESS',
  GET_ALL_IDEAS_PORTFOLIO_FAILURE: 'GET_ALL_IDEAS_PORTFOLIO_FAILURE',

  LOAD_CURATION_CHALLENGES_FILTERL: "LOAD_CURATION_CHALLENGES_FILTER",
  LOAD_CURATION_STATUS_FILTER: "LOAD_CURATION_STATUS_FILTER",
  LOAD_CURATION_CHALLENGE_FILTER_REQUEST: "LOAD_CURATION_CHALLENGE_FILTER_REQUEST",
  LOAD_CURATION_CHALLENGE_FILTER_SUCCESS: "LOAD_CURATION_CHALLENGE_FILTER_SUCCESS",
  LOAD_CURATION_CHALLENGE_FILTER_FAILURE: "LOAD_CURATION_CHALLENGE_FILTER_FAILURE",
  CLEAR_CURATION_FILTER: "CLEAR_CURATION_FILTER",
  CHANGE_CURATION_PROP: "CHANGE_CURATION_PROP",

  CURATION_TAB_ESTEIRA: "esteira",
  CURATION_TAB_LISTAGEM: "listagem",
  SET_STATUS_FIXED: "SET_STATUS_FIXED",
  CHANGE_CURATON_SEARCH: "CHANGE_CURATON_SEARCH",
  CLEAR_CURATION_TEXT_SEARCH: "CLEAR_CURATION_TEXT_SEARCH",
  SELECT_ALL_FILTERS: "SELECT_ALL_FILTERS",
  LOAD_CURATION_STEP_FILTER: "LOAD_CURATION_STEP_FILTER",
  SUCCESS_GET_STEP_FLUX_IDEAS: "SUCCESS_GET_STEP_FLUX_IDEAS"
};
