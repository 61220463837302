import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  Button
} from '@material-ui/core';
import { ArrowBack, ArrowForward, Close, Info } from '@material-ui/icons';
import { avaliationDialogsActions } from '../../../actions';
import { HeaderTitle } from '../../EvaluationTeams/styles';
import { UploadStepFluxFiles } from '../../Upload';
import dialogVideoSvg from '../../../assets/imgs/dialog-video.svg';
import { dialogCss } from '../../CreateIdeaDialogs/dialogCss';

export const VideoDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dialogClasses = dialogCss();
  const { videoDialog } = useSelector(state => state.avaliationDialogs);
  
  const handleClose = () => {
    dispatch(avaliationDialogsActions.openVideoDialog());
  };

  const handlePrev = () => {
    dispatch(avaliationDialogsActions.openVideoDialog());
    dispatch(avaliationDialogsActions.openAttachmentDialog());
  };

  const handleSave = () => {
    dispatch(avaliationDialogsActions.saveAttachments());
    handleClose();
  }

  return (
    <Dialog open={videoDialog} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={handlePrev}>
            <ArrowBack color='primary' />
          </IconButton>
          <HeaderTitle>{t('anexar_video')}</HeaderTitle>
          <IconButton onClick={handleClose}>
            <Close color='primary' />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <UploadStepFluxFiles
          type="video"
          img={dialogVideoSvg}
          name="video"
          message={t('insira_um_video_para_incrementar_a_qualificacao_da_ideia')}
          accept="video/*"
          maxSize={5242880}
        />
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <Box style={{ width: '100%' }}>
          <Box display="flex" alignItems="center">
            <Info fontSize="small" style={{ marginRight: 6 }} />
            <Typography variant="caption">
              {t('o_arquivo_deve_possuir_tamanho_maximo_de')}{' '}
              <strong>50mb</strong>.
            </Typography>
          </Box>
          <Box marginTop={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box></Box>
            <Button
              className={dialogClasses.button}
              variant="contained"
              color="primary"
              startIcon={<ArrowForward />}
              onClick={handleSave}
            >
              {t('salvar_anexo')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
