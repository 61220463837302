import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography, Button, TextField } from '@material-ui/core';

import { DialogCustom } from '../../Shared';

export function DialogForwardIdea({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="encaminhar"
      content={<>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{t('encaminhar_ideia')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {t('direcione_essa_avaliacao_para_uma_outra_area_caso_identifique_que_a_sua_area_nao_e_a_mais_qualificada_para_isso')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="filled"
            name="businessUnit"
            label={t('unidade_de_negocio')}
            // defaultValue={businessUnit}
            // onChange={handleChangeBusinessUnit}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="filled"
            name="local"
            label={t('local')}
            // defaultValue={businessUnit}
            // onChange={handleChangeBusinessUnit}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="filled"
            name="ocupationArea"
            label={t('area_de_atuacao')}
            // defaultValue={businessUnit}
            // onChange={handleChangeBusinessUnit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            minRows={6}
            variant="filled"
            name="observations"
            label={t('observacoes')}
            // defaultValue={businessUnit}
            // onChange={handleChangeBusinessUnit}
          />
        </Grid>
      </>}
      action={
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
          >
            {t('encaminhar')}
          </Button>
        </Box>
      }
    />
  );
}