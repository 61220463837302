import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { CardEmptyState } from './styles';
import { IdeaGeneralEvaluationTabs } from './IdeaGeneralEvaluationTabs';
import { IdeaFollowUp } from './IdeaFollowUp';
import { IdeaEvaluation } from './IdeaEvaluation';
import { IdeaEvaluationDetails } from './IdeaEvaluationDetails';
import { IdeaAvaliatorsAndComments } from './IdeaAvaliatorsAndComments';
import { ideaGeneralEvaluationActions } from '../../../actions';
import { SkeletonIdeaEvaluation } from '../../Skeletons';
import { EmptyState } from '../../Shared';
import emptyStateIcon from '../../../assets/imgs/emptystate/illustration_empty-state-general-evaluation.svg';

export function IdeaGeneralEvaluationLayout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('evaluation');
  const { _id: ideaId, personalizedEvaluationGroup } = useSelector(state => state.idea.ideaDetails);
  const { evaluationDetails, loading } = useSelector(state => state.ideaGeneralEvaluation);
  const userRated = evaluationDetails.personalizedEvaluationRates?.length > 0;

  useEffect(() => {
    if (selectedTab === 'evaluation') {
      dispatch(ideaGeneralEvaluationActions.getEvaluationByIdeaIdAndUser(ideaId));
      dispatch(ideaGeneralEvaluationActions.getEvaluationsByGroup(personalizedEvaluationGroup?._id));
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === 'follow-up') {
      dispatch(ideaGeneralEvaluationActions.getIndicatorsByIdea(ideaId));
      dispatch(ideaGeneralEvaluationActions.getCommentsByIdea(ideaId));
    }
  }, [selectedTab]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {t('avaliacao_geral').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <IdeaGeneralEvaluationTabs selectedTab={selectedTab} handleChangeTab={setSelectedTab} />
      </Grid>

      {selectedTab === 'evaluation' && (<>
        {loading ? (
          <Grid item xs={12}>
            <SkeletonIdeaEvaluation />
          </Grid>
        ) : (<>
          {userRated ? (
            <Grid item xs={12}>
              <IdeaEvaluationDetails groupName={personalizedEvaluationGroup.description} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <IdeaEvaluation
                groupName={personalizedEvaluationGroup.description}
                ideaId={ideaId}
                groupId={personalizedEvaluationGroup._id}
              />
            </Grid>
          )}
        </>)}
      </>)}
      
      {selectedTab === 'follow-up' && (<>
        {loading ? (
          <Grid item xs={12}>
            <SkeletonIdeaEvaluation />
          </Grid>
        ) : (<>
          {userRated ? (
            <>
              <Grid item xs={12}>
                <IdeaFollowUp />
              </Grid>
              <Grid item xs={12}>
                <IdeaAvaliatorsAndComments />
              </Grid>
            </>
          ) : (
            <CardEmptyState item xs={12}>
              <EmptyState
                icon={emptyStateIcon}
                description={
                <Box maxWidth="500px" display="flex" flexDirection="column">
                  <span style={{ fontWeight: 600 }}>
                    {t('ainda_nao_ha_avaliacao_cadastrada')}
                  </span>
                  {t('que_tal_fazer_a_avaliacao_para_incrementar_o_processo_de_analise_das_ideias')}
                </Box>
                }
              />
            </CardEmptyState>
          )}
        </>)}
      </>)}
    </Grid>
  );
}