import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, makeStyles, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  ideaActions,
  portfolioExecutionFlow,
  personalizedClassificationActions
} from '../../actions';
import { BackNavAdmin, IdeaDetailsLayout, FluxOfIdeasLayout } from '../../components';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
}));

export const IdeaDetailsPage = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ideaDetails, loading } = useSelector((state) => state.idea);
  const { typeProfile, _id: userId } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(portfolioExecutionFlow.getProjects(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(personalizedClassificationActions.getPersonalizedClassification());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(ideaActions.clearIdeaDetails());
  }, [dispatch]);

  function handleEditIdea() {
    history.push(`/idea-edit/${ideaDetails._id}`);
  }

  return (
    <>
      <BackNavAdmin title={loading ? 'carregando...' : ideaDetails?.name}>
        {(ideaDetails?.contributors?.filter(item => item?._id === userId).length > 0 || ideaDetails?.user?._id === userId || typeProfile.type === 'admin') && 
         (ideaDetails?.statusIdea?.code !== 'CANCELED' && ideaDetails?.statusIdea?.code !== 'CONCLUDED' && ideaDetails?.statusIdea?.code !== 'UNQUALIFIED') && (
          <Button
            color="primary"
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={handleEditIdea}
            disabled={loading}
          >
            {t('editar_ideia')}
          </Button>
        )}
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        {config.SHOW_IDEAS_BY_STEP_FLUX === false ? (
          <IdeaDetailsLayout />
        ) : (
          <FluxOfIdeasLayout />
        )}
      </Container>
    </>
  );
}
