import axios from 'axios';
import config from '../config';
import { requestHeaders } from '../helpers';

export const evaluationTeamsService = {
  createEvaluationTeam,
  getAllEvaluationTeams,
  deleteEvaluationTeam,
  updateEvaluationTeam,
}

function createEvaluationTeam(data) {
  return axios.post(`${config.URL_BASE}/evaluation-teams`, data, requestHeaders());
}

function getAllEvaluationTeams() {
  return axios.get(`${config.URL_BASE}/evaluation-teams`, requestHeaders());
}

function deleteEvaluationTeam(teamId) {
  return axios.delete(`${config.URL_BASE}/evaluation-teams/${teamId}`, requestHeaders());
}

function updateEvaluationTeam(teamId, data) {
  return axios.put(`${config.URL_BASE}/evaluation-teams/${teamId}`, data, requestHeaders());
}