import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';

import {
  HistoryCard,
  QualificationTabs,
  QualificationCard,
  ResourcesCard
} from '../Qualification';
import { Card } from './styles';
import { EmptyState } from '../../Shared';
import { fluxOfIdeasActions } from '../../../actions';
import { generalEvaluationIcon }  from '../../../assets/imgs';

export function QualificationLayout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('qualification');
  const { ideaDetails } = useSelector(state => state.idea);

  useEffect(() => {
    if (ideaDetails?.personalizedEvaluationGroup?._id || ideaDetails?.currentStepFlux?.ideaStepFlux?.personalizedEvaluationsGroups[0]) {
      dispatch(fluxOfIdeasActions.getEvaluationsByGroup(
        ideaDetails?.personalizedEvaluationGroup?._id || ideaDetails?.currentStepFlux?.ideaStepFlux?.personalizedEvaluationsGroups[0]
      ));
    }
  }, [ideaDetails]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {t('qualificar_ideia').toLocaleUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <QualificationTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </Grid>
      <Grid item xs={12}>
        {selectedTab === 'qualification' && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <QualificationCard />
            </Grid>
            <Grid item xs={12}>
              <ResourcesCard />
            </Grid>
          </Grid>
        )}
        {selectedTab === 'history' && (
          ideaDetails.evaluationStepsHistory.length > 0 ? (
            <HistoryCard />
          ) : (
            <Card elevation={0} style={{ padding: 32 }}>
              <EmptyState
                icon={generalEvaluationIcon}
                title={t('ainda_nao_ha_um_historico_para_mostrar')}
                description={t('os_eventos_serao_exibidos_aqui_quando_as_ideias_forem_avaliadas')}
              />
            </Card>
          )
        )}
      </Grid>
    </Grid>
  );
}