import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Grid, styled } from '@material-ui/core';
import MuiContainer from '@material-ui/core/Container';

import { BackNavAdmin, CustomSelect, SidebarManageAccess } from '../../components';
import { optionConstants } from '../../constants';
import { AdminUserEditPage } from '../AdminUserEditPage';
import { UserFunctionPage } from '../UserFunctionPage';
import { UserPoints } from '../UserPoints';
import { useWindowSize } from '../../hooks';
import config from '../../config';

export function AdminUserEditLayout() {
  const { t } = useTranslation();
  const [type, setType] = useState('atribuir-funcao');
  const { id } = useParams();
  const [width] = useWindowSize();

  const title = useMemo(() => {
    switch (type) {
      case 'atribuir-funcao':
        return 'atribuir_funcao_ao_usuario';
      case 'editar-dados':
        return 'editar_dados_do_usuario';
      case 'gerenciar-pontos':
        return 'gerenciar_pontos';
      default:
        return '';
    }
  }, [type]);

  const Container = styled(MuiContainer)({
    marginTop: 32
  });

  return (
    <>
      <BackNavAdmin title={t(title)} />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {width < config.RESPONSIVE_MOBILE ? (
            <Grid item xs={12}>
              <CustomSelect
                optionsAvaiable={[
                  optionConstants.ATRIBUIR_FUNCAO_AO_USUARIO,
                  optionConstants.EDITAR_DADOS_DE_USUARIO,
                  optionConstants.GERENCIAR_PONTOS,
                ]}
                startOption={optionConstants.ATRIBUIR_FUNCAO_AO_USUARIO}
                handlePage={setType}
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <SidebarManageAccess type={type} setType={setType} />
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            {type === 'atribuir-funcao' && <UserFunctionPage id={id} />}
            {type === 'editar-dados' && <AdminUserEditPage id={id} />}
            {type === 'gerenciar-pontos' && <UserPoints id={id} />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}