import { ideaConstants, ideaCreateConstans } from '../constants';
import { ideaService } from '../services';
import { history } from '../helpers';
import { alertActions } from './alert.actions';
import config from '../config';

export const ideaActions = {
  getIdeasUser,
  getIdeaDetails,
  toggleFilter,
  deleteDraft,
  getIdeasUserMobile,
  setIdApproved,
  clearApproved,
  setEvaluation,
  setApproved,
  sendApproved,
  setIdDelegate,
  setBuls,
  clearDelegate,
  sendDelegate,
  clearIdeaDetails,
  getPendingIdeas,
  getStatusIdeas,
  setDisplayPage,
  sendTimelineView,
  setPersonalizedClassification,
  setPersonalizedClassificationIdeiaDetails,
  setQualification,
  setQualificationFieldsErrors
};

function getIdeasUser(
  page = 1,
  sort = { created: -1 },
  query,
  limit,
  statusCode
) {
  return (dispatch) => {
    dispatch(request());
    ideaService
      .getIdeasUser(page, sort, query, limit, statusCode)
      .then((ideas) => {
        dispatch(success(ideas, query));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: ideaConstants.GET_USER_IDEAS_IMAGINE_REQUEST };
  }

  function success(ideas, query) {
    const payload = {
      ideas,
      query,
    };

    return { type: ideaConstants.GET_USER_IDEAS_IMAGINE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: ideaConstants.GET_USER_IDEAS_IMAGINE_FAILURE };
  }
}

function getIdeasUserMobile(
  page = 1,
  sort = { created: -1 },
  query,
  limit,
  statusCode
) {
  return (dispatch) => {
    dispatch(request());
    ideaService
      .getIdeasUser(page, sort, query, limit, statusCode)
      .then((ideas) => {
        dispatch(success(ideas, query));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: ideaConstants.GET_USER_IDEAS_IMAGINE_REQUEST_MOBILE };
  }

  function success(ideas, query) {
    const payload = {
      ideas,
      query,
    };

    return {
      type: ideaConstants.GET_USER_IDEAS_IMAGINE_SUCCESS_MOBILE,
      payload,
    };
  }

  function failure(error) {
    return { type: ideaConstants.GET_USER_IDEAS_IMAGINE_FAILURE_MOBILE };
  }
}

function getIdeaDetails(id) {
  return (dispatch) => {
    dispatch({ type: ideaConstants.GET_IDEA_USER_DETAILS_REQUEST });
    ideaService.getIdeaDetails(id).then(
      (idea) => {
        dispatch({
          type: ideaConstants.GET_IDEA_USER_DETAILS_SUCCESS,
          payload: {
            idea
          }
        });
      },
      (error) => {
        dispatch({
          type: ideaConstants.GET_IDEA_USER_DETAILS_FAILURE,
          error
        });
      }
    );
  }
}

function deleteDraft(id, code, successMessage) {
  return (dispatch) => {
    dispatch({
      type: ideaConstants.DELETE_DRAFT_REQUEST
    })
    ideaService.deleteDraft(id).then(
      () => {
        dispatch(success(id));
        dispatch(alertActions.success(successMessage));

        if (code !== "DRAFT") {
          history.goBack()
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error));
      }
    );
  };

  function success(id) {
    const payload = {
      id,
    };
    return { type: ideaConstants.DELETE_DRAFT_SUCCESS, payload };
  }

  function failure(error) {
    return { type: ideaConstants.DELETE_DRAFT_FAILURE, error };
  }
}

function toggleFilter(filter) {
  return (dispatch) => {
    dispatch(success());

    function success() {
      const payload = {
        filter,
      };
      return { type: ideaConstants.TOGGLE_FILTER, payload };
    }
  };
}

function setIdApproved(id) {
  const payload = {
    id,
  };

  return { type: ideaConstants.SET_ID_APPROVED, payload };
}

function clearApproved() {
  return { type: ideaConstants.CLEAR_APPROVED };
}

function setEvaluation(evaluation) {
  const payload = {
    evaluation,
  };

  return { type: ideaConstants.SET_EVALUATION_APPROVED, payload };
}

function setPersonalizedClassification(personalizedClassifications) {
  const payload = {
    personalizedClassifications,
  };

  return { type: ideaConstants.SET_PERSONALIZED_CLASSIFICATION_APPROVED, payload };
}
function setPersonalizedClassificationIdeiaDetails(personalizedClassifications) {
  const payload = {
    personalizedClassifications,
  };

  return { type: ideaConstants.SET_PERSONALIZED_CLASSIFICATION_IDEA_DETAILS, payload };
}



function setApproved(approved) {
  const payload = {
    approved,
  };

  return { type: ideaConstants.SET_APPROVED, payload };
}

function sendApproved(approved, successMessage) {
  return (dispatch) => {
    ideaService
      .sendApproved(approved)
        .then(() => {
          dispatch(alertActions.success(successMessage));
          history.goBack();
        })
        .catch((error) => {
          dispatch(failure(error));
        });
  };

  function failure(error) {
    return { type: ideaConstants.PUT_IDEA_APPROVED_FAILURE, error };
  }
}

function setIdDelegate(id) {
  const payload = {
    id,
  };
  return { type: ideaConstants.SET_ID_DELEGATE, payload };
}

function clearDelegate() {
  return { type: ideaConstants.CLEAR_DELEGATE };
}

function sendDelegate(delegate, successMessage) {
  return (dispatch) => {
    ideaService
      .sendDelegate(delegate)
      .then(() => {
        dispatch(alertActions.success(successMessage));
        history.push('/curation');
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function failure(error) {
    return { type: ideaConstants.PUT_IDEA_DELEGATE_FAILURE, error };
  }
}

function setBuls(buls) {
  const payload = {
    buls,
  };

  return (dispatch) => dispatch({ type: ideaConstants.SET_BUL, payload });
}

function clearIdeaDetails() {
  return { type: ideaConstants.CLEAR_IDEA_DETAILS };
}

function getPendingIdeas() {
  return (dispatch) => {
    ideaService
      .getPendingIdeas()
      .then((data) => {
        dispatch(success(data.data));
      })
      .catch((error) => {
        failure(error);
      });
  };

  function success(data) {
    const payload = {
      data,
    };

    return { type: ideaConstants.GET_PENDING_IDEAS_SUCCESS, payload };
  }

  function failure(error) {
    return { type: ideaConstants.GET_PENDING_IDEAS_FAILURE };
  }
}

function getStatusIdeas() {
  return (dispatch) => {
    ideaService
      .getStatusIdeas()
      .then((data) => {
        dispatch(success(data.data));
      })
      .catch((error) => {
        failure(error);
      });
  };

  function success(data) {
    const payload = {
      data,
    };

    return { type: ideaConstants.GET_STATUS_IDEAS_SUCCESS, payload };
  }

  function failure(error) {
    return { type: ideaConstants.GET_STATUS_IDEAS_FAILURE };
  }
}

function setDisplayPage(page) {
  return dispatch => {
    dispatch({
      type: ideaConstants.SET_IDEA_DETAILS_PAGE,
      payload: page
    })
  }
}

function sendTimelineView(id, status) {
  return (dispatch) => {
    dispatch({
      type: ideaConstants.TOGGLE_IDEA_IN_TIMELINE_REQUEST,
      payload: status
    });

    ideaService.sendTimelineView(id, status).then(
      (response) => {
        dispatch({
          type: ideaConstants.TOGGLE_IDEA_IN_TIMELINE_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          type: ideaConstants.TOGGLE_IDEA_IN_TIMELINE_FAILURE,
          error
        });
      }
    );
  }
}

function setQualification(qualification) {
  return dispatch => {
    dispatch({ 
      type: ideaConstants.SET_IDEA_QUALIFICATION,
      payload: qualification
    })
  }
}

function setQualificationFieldsErrors() {
  return dispatch => {
    dispatch({ 
      type: ideaConstants.SET_QUALIFICATION_FIELDS_ERRORS
    })
  }
}