import { styled } from '@material-ui/core';
import {
  Card as MuiCard,
  Box as MuiBox,
  Slider as MuiSlider,
  LinearProgress as MuiLinearProgress
} from '@material-ui/core';

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.neutrals.high.main,
  border: `1px solid ${theme.palette.neutrals.high.medium}`
}));

export const QualificationActions = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '20px 32px 32px',
  gap: 20,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const DateAndTimeContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  color: theme.palette.neutrals.low.light
})); 

export const Slider = styled(MuiSlider)({
  width: '90%',
  height: 6,

  '& .MuiSlider-track': {
    height: 6,
  },
  '& .MuiSlider-rail': {
    height: 6,
  },
});

export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  width: '100%',
  height: 8,
  borderRadius: 5,
  margin: theme.spacing(0, 1),
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const Indicators = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(1.5, 0)
}));

export const BoxAttachment = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  background: theme.palette.neutrals.high.lightest,
  borderRadius: 8,
  gap: 10
}));