import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography, Button
} from '@material-ui/core';
import { ArrowBack, ArrowForward, Close, Info } from '@material-ui/icons';
import { avaliationDialogsActions } from '../../../actions';
import { HeaderTitle } from '../../EvaluationTeams/styles';
import { UploadStepFluxFiles } from '../../Upload';
import dialogFileSvg from '../../../assets/imgs/dialog-file.svg';
import { dialogCss } from '../../CreateIdeaDialogs/dialogCss';

export const FileDialog = () => {
  const dialogClasses = dialogCss();
  const { documentDialog } = useSelector(state => state.avaliationDialogs);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(avaliationDialogsActions.openDocumentDialog());
  };

  const handlePrev = () => {
    dispatch(avaliationDialogsActions.openDocumentDialog());
    dispatch(avaliationDialogsActions.openAttachmentDialog());
  };

  const handleSave = () => {
    dispatch(avaliationDialogsActions.saveAttachments());
    handleClose();
  }

  return (
    <Dialog open={documentDialog} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={handlePrev}>
            <ArrowBack color='primary' />
          </IconButton>
          <HeaderTitle>{t('anexar_arquivo')}</HeaderTitle>
          <IconButton onClick={handleClose}>
            <Close color='primary' />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <UploadStepFluxFiles
          type="file"
          img={dialogFileSvg}
          name="arquivo"
          message={t('insira_um_documento_como_complemento_da_sua_qualificcao.')}
          accept=".pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,application/msword,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation"
          maxSize={5242880}
        />
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <Box style={{ width: '100%' }}>
         <Box display="flex" alignItems="center">
            <Info fontSize="small" style={{ marginRight: 6 }} />
            <Typography variant="caption">
              {t('o_arquivo_deve_possuir_tamanho_maximo_de')}{' '}
              <strong>50mb</strong>.
            </Typography>
          </Box>
          <Box marginTop={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box></Box>
            <Button
              className={dialogClasses.button}
              variant="contained"
              color="primary"
              startIcon={<ArrowForward />}
              onClick={handleSave}
            >
              {t('salvar_anexo')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};