import React, { useEffect } from 'react';
import { makeStyles, withStyles, useTheme, Container, Grid, Tabs } from '@material-ui/core';
import MuiTab from '@material-ui/core/Tab';
import {
  BackNavAdmin,
  CurationTitle,
  CurationTable,
  CurantionCardsStatus,
  CurationMenusTable,
  CurationCard,
  Loading,
  CurationFilters,
  SkeletonTable
} from '../../components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { curationActions, ideaActions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';
import config from '../../config';
import ListIcon from '@material-ui/icons/List';
import { ReactComponent as IdeaIcon } from '../../assets/imgs/ideiaPurple.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
}));

const Tab = withStyles({
  wrapper: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase',
  }
})(MuiTab);

function CurationIdeasMatPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { ideasStepsFlux } = useSelector(state => state.domains);
  const {
    paging,
    loading,
    filters,
  } = useSelector((state) => state.curation);

  useEffect(() => {
    dispatch(curationActions.loadAllSteps(ideasStepsFlux.map(item => ({
      label: item.name,
      value: item._id,
      checked: true
    }))));

    dispatch(curationActions.getStepFluxIdeas({
      statusCode: [],
      stepsIdeas: filters?.steps.filter(({ value }) => value !== 'SELECT_ALL')
        .map(({ value }) => value),
      challenges: [],
      page: 1,
      limit: 10
    }));
  }, []);

  return (
    <>
      <BackNavAdmin title={t('curadoria')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab value="listagem" label={t('listagem_de_ideias')} />
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <CurationTitle
              title={t('lista_de_ideias')}
              icon={<ListIcon color="primary" />}
              info={t('na_listagem_e_possivel_visualizar_todas_as_ideias_filtrando_as_por_status_e_por_nome_ou_desafio')}
            />
            <CurationFilters />
            <Grid container>
              <Grid item xs={12}>
                <CurationTable />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export { CurationIdeasMatPage };
