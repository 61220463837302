import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';
import { authHeader } from '../helpers/auth-headers';

export const challengeService = {
  getChallenges,
  createChallenge,
  uploadImageChallenge,
  getChallengeById,
  getIdeasChallenge,
  updateChallenge,
  removeImage,
  deleteChallenge,
  postWinners,
  getChallengesActive
};

function getChallenges(page, sort, filter, limit) {
  return axios
    .get(
      `${config.URL_BASE}/challenges?page=${page}&sort=${JSON.stringify(
        sort
      )}&filter=${JSON.stringify(filter)}&limit=${limit}`,
      requestHeaders()
    )
    .then(handleResponse);
}

function getChallengeById(id) {
  return axios
    .get(`${config.URL_BASE}/challenges/${id}`, requestHeaders())
    .then(handleResponse);
}

function getIdeasChallenge(id, page, sort, limit, status) {
  return axios
    .get(
      `${
        config.URL_BASE
      }/ideas/challenge/${id}?page=${page}&sort=${JSON.stringify(
        sort
      )}&quantityPerPage=${limit}&statusCode=${JSON.stringify(status)}`,
      requestHeaders()
    )
    .then(handleResponse);
}

function getChallengesActive() {
  return axios
    .get(
      `${config.URL_BASE}/challenges/active`,
      requestHeaders()
    )
    .then(handleResponse);
}

function createChallenge(challenge) {
  challenge.rewards = challenge.rewards.map((awards, index) => ({
    index: index + 1,
    score: awards.score,
    description: awards.description,
  }));

  return axios
    .post(`${config.URL_BASE}/challenges`, challenge, requestHeaders())
    .then(handleResponse);
}

function updateChallenge(challenge) {
  return axios
    .put(
      `${config.URL_BASE}/challenges/${challenge._id}`,
      challenge,
      requestHeaders()
    )
    .then(handleResponse);
}

function uploadImageChallenge(type, file, id) {
  const data = new FormData();

  data.append(type, file.file, file.name);

  return axios.post(`${config.URL_BASE}/uploads/challenges/${id}`, data , {
    headers: authHeader(),
  });
};

function removeImage(id, type) {
  return axios
    .delete(`${config.URL_BASE}/challenges/${id}/images?type=${type}`, requestHeaders())
    .then(handleResponse);
};

function deleteChallenge(id) {
  return axios
    .delete(`${config.URL_BASE}/challenges/${id}`, requestHeaders())
    .then(handleResponse);
};

function postWinners(winners) {
  const id = winners[0].idea?.challenge?._id || winners[0].idea?.challenge;

  const body = winners.map(({ position, idea }) => ({...idea, position}));

  return axios.put(`${config.URL_BASE}/challenges/${id}/winners`, body, requestHeaders());
 
};
