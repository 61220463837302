import React from 'react';
import { Chip as MuiChip, styled } from '@material-ui/core';

export function ChipEvaluation({ label, bg }) {

  const Chip = styled(MuiChip)(({ theme, bg }) => ({
    color: theme.palette.neutrals.high.main,
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
    backgroundColor: bg,
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5)
  }));

  return <Chip label={label} bg={bg} size="small" />;
}