import React from 'react';
import { styled } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const Typography = styled(MuiTypography)(({ theme, step }) => ({
  color: theme.palette.neutrals.high.main,
  fontSize: 11,
  fontWeight: 600,
  textTransform: 'uppercase',
  backgroundColor: step?.labelColor,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  width: 'max-content'
}));


export function StepIdea({ step }) {
  return <Typography step={step}>{step?.name}</Typography>;
}