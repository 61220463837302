import React, { Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  Box,
  TablePagination,
} from "@material-ui/core";
import { reportActions } from "./../../actions";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { formatDate } from '../../utils/date';
import { tableCss } from './../Tables/TableCss';
import { useTranslation } from 'react-i18next';
import { ReportEmptyState } from './ReportEmptyState';

export const TablePanel = ({ tableInfo }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const tableClasses = tableCss({
    text: t('linhas_por_pagina'),
  });
  const dispatch = useDispatch();
  const { report, paging, filters, loading } = useSelector(state => state.report)
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps)
  const classes = useStyles();

  function handleChangePage(event, page) {
    let newPage = page + 1;
    const splittedPathname = history.location.pathname.split("/");

    const data = {
      statusCode: filters.status.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      businessUnits: businessUnit.filter(item => item?.checked && item?.value !== "SELECT_ALL").map(item => item?.value),
      locals: local.filter(item => item?.checked && item?.value !== "SELECT_ALL").map(item => item?.value),
      occupationArea: occupationArea.filter(item => item?.checked && item?.value !== "SELECT_ALL").map(item => item?.value),
      startDate: formatDate(filters.startDate),
      endDate: formatDate(filters.endDate),
      page: newPage,
      limit: paging.limit,
      reportType: splittedPathname[splittedPathname.length - 1],
      bulWithoutUsers: filters.bulWithoutUsers,
      ideasUngrouped: filters.ideasUngrouped,
      ideaDescriptionOnCsv: filters.ideaDescriptionOnCsv,
      useConclusionDate: filters.useConclusionDate,
    }

    dispatch(reportActions.getReportData(data))
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    const splittedPathname = history.location.pathname.split("/");

    const data = {
      statusCode: filters.status.map(item => item.value),
      businessUnits: businessUnit.filter(item => item?.checked && item?.value !== "SELECT_ALL").map(item => item?.value),
      locals: local.filter(item => item?.checked && item?.value !== "SELECT_ALL").map(item => item?.value),
      occupationArea: occupationArea.filter(item => item?.checked && item?.value !== "SELECT_ALL").map(item => item?.value),
      startDate: formatDate(filters.startDate),
      endDate: formatDate(filters.endDate),
      page: 1,
      limit: limit,
      reportType: splittedPathname[splittedPathname.length - 1],
    }

    dispatch(reportActions.getReportData(data))
  }

  function handleSelectedColumnChecked(columnName) {
    return filters.properties.filter(({ value }) => value === columnName)[0]?.checked;
  }

  return (
    <Fragment>
      {loading ? (
        <Paper className={classes.noContent} elevation={0}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {t('carregando_informacoes')}
          </Box>
        </Paper>
      ) : (
        <Fragment>
          {report.length > 0 ? (
            <Fragment>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="report table"
                  classes={{ root: tableClasses.table }}
                >
                  <TableHead>
                    <TableRow>
                      {tableInfo.columns.map((column) => {
                        if (handleSelectedColumnChecked(column.id)) return <TableCell
                          key={column.id}
                          classes={{ root: tableClasses.tableCellHeader }}
                        >
                          {column.label}
                        </TableCell>
                      }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableInfo.data.map((item, index) => (
                      <TableRow
                        hover
                        key={index}
                        classes={{ root: tableClasses.tableRow }}
                      >
                        {item}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                classes={{
                  input: tableClasses.paginationInput,
                  spacer: tableClasses.paginationSpacer,
                  caption: tableClasses.paginationCaption,
                  toolbar: tableClasses.paginationToolbar,
                }}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                count={paging.total}
                rowsPerPage={paging.limit}
                page={paging.page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('exibindo')}
              />
            </Fragment>
          ) : (
            <ReportEmptyState />
          )
          }
        </Fragment>
      )}
    </Fragment>
  )
}
