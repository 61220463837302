import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const fluxOfIdeasService = {
  createIdeaInFlux,
  getIdeaInFlux,
  updateIdeaStageInFlux,
  uploadFiles,
  getAllEvaluationsByGroup,
  ideaVisibleInTimeline,
  getIdeasUserStepFlux
}

function createIdeaInFlux(data) {
  return axios
    .post(`${config.URL_BASE}/ideas/by-step-flux`, data, requestHeaders());
}

function getIdeaInFlux(ideaId) {
  return axios
    .get(`${config.URL_BASE}/ideas/by-step-flux/${ideaId}`, requestHeaders())
    .then((response) => response.data.data);
}

function updateIdeaStageInFlux(ideaId, data) {
  return axios.put(`${config.URL_BASE}/ideas/by-step-flux/update-step/${ideaId}`, data, requestHeaders());
}

function uploadFiles(files, ideaId) {
  const promises = files.map((file) => {
    const data = new FormData();

    data.append('file', file.file);

    return axios.post(`${config.URL_BASE}/uploads/idea/by-step-flux/${ideaId}`, data, requestHeaders());
  });

  return Promise.all(promises);
}

function getAllEvaluationsByGroup(id) {
  return axios
    .get(`${config.URL_BASE}/personalized-evaluation/by-group/${id}`, requestHeaders());
}

function ideaVisibleInTimeline(id, status) {
  return axios
    .put(`${config.URL_BASE}/ideas/${id}/timeline`, {  timeline: status }, requestHeaders())
    .then(handleResponse);
}

function getIdeasUserStepFlux(page=1, createdAt={}, query='', limit=10, stepsIdeas=[]) {
  return axios
    .get(`${config.URL_BASE}/ideas/user-by-step-flux?q=${query}&page=${page}&sort=${JSON.stringify(createdAt)}&quantityPerPage=${limit}&stepsIdeas=${JSON.stringify(stepsIdeas)}`, 
    requestHeaders())
}