import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, CircularProgress } from '@material-ui/core';
import {
  IdeaGeneral,
  IdeaResults,
  IdeaExecution,
  IdeaConclusion,
  IdeaEngagement,
  IdeaGeneralEvaluationLayout,
  EmptyState,
  SidebarIdeaDetails,
  QualificationLayout
} from '../../components';
import {
  SkeletonCardIdeaDetails,
  SkeletonSidebarIdeaDetails
} from '../Skeletons/SkeletonIdeaDetails';
import { fluxOfIdeasActions } from '../../actions';
import emptyStateIcon from '../../assets/imgs/emptystate/illustration_empty-state-general-evaluation.svg';

export function FluxOfIdeasLayout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, page } = useParams();
  const { ideaDetails, loading } = useSelector((state) => state.idea);

  useEffect(() => {
    if (['general', 'qualification', 'general-evaluation', 'execution', 'conclusion', 'results', 'engagement'].includes(page)) {
      if (!ideaDetails?._id || ideaDetails?._id !== id) {
        dispatch(fluxOfIdeasActions.getIdeaInFlux(id));
      }
    } else {
      history.goBack();
    }
  }, [page, id]);
  
  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        {loading ? <SkeletonSidebarIdeaDetails /> : <SidebarIdeaDetails page={page} />}
      </Grid>
      <Grid item xs={12} md={9}>
        {loading ? <SkeletonCardIdeaDetails /> : page && (
          <>
            {page === "general" && ideaDetails?._id && <IdeaGeneral />}

            {page === "qualification" && ideaDetails?._id && <QualificationLayout />}
          
            {/* 
            {loading ? <CircularProgress /> : (
              page === "execution" && ideaDetails._id && !!projects?.length ? 
              (<LinkedProjects projects={projects} />) : (
              <IdeaExecution />
            ))} */}

            {page === "general-evaluation" && ideaDetails?._id && (<>
              {ideaDetails?.personalizedEvaluationGroup !== undefined 
                ? <IdeaGeneralEvaluationLayout />
                : <EmptyState icon={emptyStateIcon} />
              }
            </>)}

            {loading ? <CircularProgress /> : 
              page === "execution" && ideaDetails?._id && (
              <IdeaExecution />
            )}

            {page === "conclusion" && ideaDetails?._id && (
              <IdeaConclusion />
            )}

            {page === "results" && ideaDetails?._id && (
              <IdeaResults />
            )}

            {page === "engagement" && ideaDetails?._id && (
              <IdeaEngagement />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}