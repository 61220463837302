import { userFunctionConstants } from "./../constants";

const initialState = { 
  loadingSubmitUser: false,
  dialogConfirmUser: false,
  dialogCancelUser: false,
  dialogDeleteUser: false,
  dialogConfirmRecoverPassword: false,
  error: false,
  users: [],
  userType: [],
  selectedUser: false,
  loadingUser: false,
  selectedProfileType: false,
  selectedBusiness: [],
  adminUserEdit: false,
  adminUserEditError: false,
  adminUserEditSuccess: false,
  adminUserEditLoading: false,
  sendEmailPasswordSuccess: false,
  sendEmailPasswordError: false,
}

export function userFunction(state = initialState, action) {
  switch(action.type) {

    case userFunctionConstants.GET_MANAGE_USERS_SUCCESS:
      return { 
        ...state,
        users: action.payload.users,
      }

    case userFunctionConstants.GET_MANAGE_USER_SUCCESS: {
      return {
          ...state,
          users: action.payload.users,
          locals: action.payload.locals,
          selectedUser: action.payload.user,
          selectedProfileType: action.payload.profileType,
          selectedBusiness: action.payload.businessUnitLocals
        }
      }

    case userFunctionConstants.GET_USER_TYPE_SUCCESS:
      return { 
        ...state,
        userType: action.payload
      }

    case userFunctionConstants.SET_USER: 
      return { 
        ...state,
        selectedUser: action.payload
      }

    case userFunctionConstants.SET_USER_TYPE: 
      return { 
        ...state,
        selectedProfileType: action.payload
      }

    case userFunctionConstants.CLEAR_USER_FUNCTION_STATE:
      return initialState;

    case userFunctionConstants.OPEN_DIALOG_CONFIRM_USER: 
      return {
        ...state,
        dialogConfirmUser: true
      }

    case userFunctionConstants.CLOSE_DIALOG_CONFIRM_USER:
      return {
        ...state,
        dialogConfirmUser: true
      }

    case userFunctionConstants.OEPN_DIALOG_CANCEL_USER: 
      return {
        ...state,
        dialogCancelUser: true
      }

    case userFunctionConstants.CLOSE_DIALOG_CANCEL_USER:
      return { 
        ...state,
        dialogCancelUser: false
      }

    case userFunctionConstants.TOGGLE_DIALOG_CANCEL_USER: 
      return {
        ...state,
        dialogCancelUser: !state.dialogCancelUser 
      }

    case userFunctionConstants.TOGGLE_DIALOG_CONFIRM_USER: 
      return {
        ...state,
        dialogConfirmUser: !state.dialogConfirmUser
      }

    case userFunctionConstants.SUBMIT_USER_REQUEST:
      return {
        ...state,
        loadingSubmitUser: true
      }

    case userFunctionConstants.SUBMIT_USER_SUCCESS:
      return {
        ...state,
        loadingSubmitUser: false,
        error: action.payload
      }

    case userFunctionConstants.SUBMIT_USER_FAILURE:
      return {
        ...state,
        loadingSubmitUser: false,
        error: action.payload
      }

    case userFunctionConstants.GET_ADMIN_USER_EDIT_REQUEST: {
      return {
        ...state,
        adminUserEditLoading: true,
        adminUserEdit: false,
      }
    }

    case userFunctionConstants.GET_ADMIN_USER_EDIT_SUCCESS: {
      return {
        ...state,
        adminUserEdit: action.payload,
        adminUserEditLoading: false
      }
    }

    case userFunctionConstants.UPDATE_ADMIN_USER_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case userFunctionConstants.UPDATE_ADMIN_USER_EDIT_SUCCESS: {
      return {
        ...state,
        adminUserEditSuccess: true,
        loading: false,
      }
    }

    case userFunctionConstants.UPDATE_ADMIN_USER_EDIT_FAILURE: {
      return {
        ...state,
        adminUserEditError: action.error
      }
    }

    case userFunctionConstants.CLEAR_ADMIN_USER_EDIT: {
      return {
        ...state,
        adminUserEdit: false,
      }
    }

    case userFunctionConstants.CLEAR_USER_FUNCTION_SELECTED_USER: {
      return {
        ...state,
        userType: [],
        selectedUser: false,
        selectedProfileType: false,
        selectedBusiness: [],
        loadingSubmitUser: false,
        dialogConfirmUser: false,
        dialogCancelUser: false,
        dialogDeleteUser: false,
        dialogConfirmRecoverPassword: false,
        error: false,
      }
    }

    case userFunctionConstants.GET_ADMIN_USER_EDIT_ERROR: {
      return {
        ...state,
        adminUserEditError: true,
        adminUserEditLoading: false
      }
    }

    case userFunctionConstants.CLEAR_ADMIN_USER_EDIT_ERRORS: {
      return {
        ...state,
        adminUserEditError: false
      }
    }

    case userFunctionConstants.TOGGLE_DIALOG_CONFIRM_EMAIL_RECOVER_PASSWORD: {
      return {
        ...state,
        dialogConfirmRecoverPassword: action.payload
      }
    }

    case userFunctionConstants.REQUEST_EMAIL_NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        sendEmailPasswordSuccess: true,
      }
    }

    case userFunctionConstants.REQUEST_EMAIL_NEW_PASSWORD_FAILURE: {
      return {
        ...state,
        sendEmailPasswordSuccess: false,
        sendEmailPasswordError: true
      }
    }

    case userFunctionConstants.CLEAR_EMAIL_NEW_PASSWORD: {
      return {
        ...state,
        sendEmailPasswordSuccess: false,
        sendEmailPasswordError: false
      }
    }

    case userFunctionConstants.USER_LOADING_REQUEST: {
      return {
        ...state,
        loadingUser: true,
      }
    }

    case userFunctionConstants.USER_LOADING_REQUEST_END: {
      return {
        ...state,
        loadingUser: false,
      }
    }

    case userFunctionConstants.SAVE_NEW_PASSWORD_REQUEST: {
      return {
        ...state,
        loadingUser: true,
      }
    }

    case userFunctionConstants.SAVE_NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        loadingUser: false,
      }
    }

    case userFunctionConstants.SAVE_NEW_PASSWORD_FAILURE: {
      return {
        ...state,
        loadingUser: false,
      }
    }
     
    default: 
      return state;
  }
}