import React, { useEffect, useState } from 'react';
import { makeStyles, Container, Grid, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ideaActions, fluxOfIdeasActions } from '../../actions';
import {
  Search,
  BackNavAdmin,
  DialogSendIdea,
  DialogTitleDescription,
  DialogArea,
  DialogChallenge,
  DialogPhoto,
  DialogVideo,
  DialogFile,
  DialogLink,
  DialogCanvas,
  DialogValueCanvas,
  DialogMultiArea,
  DialogClassification,
  MyIdeasFilterMenu,
  SkeletonTable,
} from '../../components';
import { IdeaCard } from '../../components/Idea';
import { useWindowSize } from '../../hooks';
import { DialogDeleteDraft, Loading } from '../../components';
import { IdeaTable } from '../../components/Tables/IdeaTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  filter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function IdeaPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    ideas,
    paging,
    loading
  } = useSelector((state) => state.idea);
  const { ideasStepsFlux } = useSelector(state => state.domains);
  const [openDialog, setOpentDialog] = useState(false);
  const [idea, setIdea] = useState({});
  const [width] = useWindowSize();
  const { t } = useTranslation();

  const dialogs = (
    <Box>
      <DialogTitleDescription />
      <DialogArea />
      <DialogMultiArea />
      <DialogChallenge />
      <DialogSendIdea isEdit={true} />
      <DialogPhoto />
      <DialogVideo />
      <DialogFile />
      <DialogLink />
      <DialogCanvas />
      <DialogValueCanvas />
      <DialogClassification />
    </Box>
  );

  useEffect(() => {
    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      dispatch(
        fluxOfIdeasActions.getIdeasUserStepFlux(
          1,
          paging.sort,
          paging.search,
          paging.limit,
          ideasStepsFlux.map(({ _id }) => _id)
        )
      );
    } else {
      dispatch(
        ideaActions.getIdeasUser(
          1,
          paging.sort,
          paging.search,
          paging.limit,
          paging.statusCode
        )
      );
    }
  }, [dispatch]);

  function loadMoreIdeas() {
    if (!loading) {
      var newPage = paging.page === 1 ? 2 : paging.page;
      dispatch(
        ideaActions.getIdeasUserMobile(
          newPage,
          paging.sort,
          paging.search,
          paging.limit,
          paging.statusCode
        )
      );
    }
  }

  function searchIdea(query) {
    dispatch(
      ideaActions.getIdeasUser(
        1,
        paging.sort,
        query,
        paging.limit,
        paging.statusCode
      )
    );
  }

  function renderTable() {
    if (ideas.length > 0) {
      return (
        <IdeaTable
          idea={idea}
          setIdea={setIdea}
          ideas={ideas}
          paging={paging}
          history={props.history}
          dialog={{ setOpentDialog: setOpentDialog, openDialog: openDialog }}
        />
      );
    }

    return null;
  }

  function renderCards() {
    if (ideas.length > 0) {
      return (
        <InfiniteScroll
          dataLength={ideas.length}
          next={loadMoreIdeas}
          hasMore={true}
          scrollThreshold={'100%'}
          loader={
            loading && (
              <Box className={classes.containerLoading}>
                <Loading />
              </Box>
            )
          }
        >
          {ideas.map((idea, index) => (
            <IdeaCard
              index={index}
              idea={idea}
              setIdea={setIdea}
              key={index}
              history={props.history}
              dialog={{
                setOpentDialog: setOpentDialog,
                openDialog: openDialog,
              }}
            />
          ))}
        </InfiniteScroll>
      );
    }

    return null;
  }

  function closeDialog() {
    setOpentDialog(false);
    setIdea({});
  }

  return (
    <>
      <BackNavAdmin title={t('minhas_ideias')} pathname="/" />
      <Container maxWidth="lg" className={classes.root}>
        {dialogs}
        <DialogDeleteDraft open={openDialog} idea={idea} onClose={closeDialog} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box className={classes.filter}>
              <MyIdeasFilterMenu />
              <Search
                query={searchIdea}
                placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            { loading ? (
              <SkeletonTable cols={7} />
            ) : (
              <>{ renderTable()}</>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { IdeaPage };
