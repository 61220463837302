import React from 'react';
import {
  Dialog,
  withStyles,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  makeStyles,
  DialogActions,
  Button,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ideaConclusionActions } from '../../actions';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'initial',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function DialogCustomTitle(props) {
  return <DialogTitle {...props} />;
}

function DialogJustification({ open, onClose, id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { errors, register, handleSubmit } = useForm({
    mode: 'onBlur',
  });
  const { loadingCancelIdea } = useSelector(state => state.ideaConclusion);

  function onSubmit(data) {
    dispatch(ideaConclusionActions.cancelConclusionIdea(id, data,t('a_execucao_da_ideia_foi_cancelada_com_sucesso')));
  }

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open}>
      <DialogCustomTitle onClose={onClose}>
        {t('justificar_execucao_nao_concluida')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.root}>
        <TextField
          error={!!errors.executionJustify}
          //   label="DESCRIÇÃO"
          style={{ margin: 8 }}
          placeholder={
            t('informe_o_motivo_da_execucao_da_ideia_nao_ter_sido_concluida') +
            '...'
          }
          fullWidth
          margin="normal"
          InputProps={{ disableUnderline: true }}
          multiline
          rows={8}
          name="executionJustify"
          inputRef={register({
            required: t('o_motivo_da_execucao_da_ideia_e_obrigatorio'),
          })}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.25rem', fontWeight: 600 },
          }}
          helperText={
            errors.executionJustify && errors.executionJustify.message
          }
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>
          {t('cancelar')}
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loadingCancelIdea}
          onClick={handleSubmit(onSubmit)}
        >
          {t('enviar_justificativa')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { DialogJustification };
