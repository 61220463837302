import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  Avatar,
  useTheme,
  Button,
  Divider
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import GroupIcon from '@material-ui/icons/Group';
import AttachmentIcon from '@material-ui/icons/Attachment';

import { CanvasRead, ValueCanvasRead } from '../CreateIdeaDialogs';
import { StatusIdea, IdeaApproverTable } from '../../components';
import { AttachmentFiles } from "./AttachmentFiles"
import { AttachmentVideo } from "./AttachmentVideo"
import { AttachmentImages } from "./AttachmentImages";
import { Author, AvatarGroupIdea, MenuInformation } from '../Shared';
import { ImagineLinkify } from "../TextLinkify";
import { ideaActions } from '../../actions';
import { history } from '../../helpers';
import { IdeaCss } from './IdeaCss';
import { ReactComponent as ValuePropositionCanvasIcon } from '../../assets/imgs/icon-valuepropositioncanvas.svg';
import { ReactComponent as InfraStructureIcon } from '../../assets/imgs/infrastucture-icon.svg';

function IdeaGeneral() {
  const classes = IdeaCss();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { ideaDetails } = useSelector((state) => state.idea);
  const [isOpenCanvas, setIsOpenCanvas] = useState(false)
  const [isOpenValueCanvas, setIsOpenValueCanvas] = useState(false)
  const user = useSelector(state => state.user);
  const { t } = useTranslation();

  function handleOnOpenValueCanvas() {
    setIsOpenValueCanvas(true)
  }

  function handleOnCloseValueCanvas() {
    setIsOpenValueCanvas(false)
  }

  function handleOnOpenCanvas() {
    setIsOpenCanvas(true)
  }

  function handleOnCloseCanvas() {
    setIsOpenCanvas(false)
  }

  function handleChange(status) {
    dispatch(ideaActions.sendTimelineView(ideaDetails._id, status));
  }

  function isFillCanvas(canvas) {
    if (!!canvas.length) {
      const hasAnswer = canvas.map((item) => item.answer?.length).some((length) => length > 0);
      return hasAnswer;
    };
    return false
  };

  function goToChallenge() {
    history.push(`/challenge/detail/${ideaDetails.challenge._id}`);
  }

  return (
    <>
      <CanvasRead idea={ideaDetails} isOpen={isOpenCanvas} onClose={handleOnCloseCanvas} />
      <ValueCanvasRead idea={ideaDetails} isOpen={isOpenValueCanvas} onClose={handleOnCloseValueCanvas} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.titleUpperCase}>
            {t('ideia_geral')}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Card elevation={0} className={classes.card} style={{ height: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginLeft: -16, marginTop: -16 }}>
                 {ideaDetails.contributors.length > 0 ? (
                  <AvatarGroupIdea user={ideaDetails.user} idea={ideaDetails} />
                ) : (
                  <Author
                    activeUser={ideaDetails.user?.status}
                    isTimeline={true}
                    userAuthor={ideaDetails.user}
                    timelineDate={ideaDetails.timelineDate}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{ideaDetails.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.texto}>
                  <ImagineLinkify>
                    {ideaDetails.description}
                  </ImagineLinkify>
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={0} className={classes.cardSmall}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Avatar className={classes.avatarIcon}>
                        <DescriptionIcon color="primary" fontSize="small" />
                      </Avatar>
                      <Typography variant="subtitle1">
                        {t('detalhes')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{t('status')}</Typography>
                    <StatusIdea status={ideaDetails.statusIdea} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{t('codigo')}</Typography>
                    <Typography variant="body2">{ideaDetails.code}</Typography>
                  </Grid>
                  {ideaDetails.challenge?.name && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{t('desafio')}</Typography>
                      <Typography variant="body2" className={classes.challengeLink} onClick={goToChallenge}>
                        {ideaDetails.challenge.name}
                      </Typography>
                    </Grid>
                  )}
                  {ideaDetails?.personalizedClassifications && ideaDetails?.personalizedClassifications.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{t('classificacao')}</Typography>
                      <Box display="flex" alignItems="center" flexDirection="column">
                        {ideaDetails?.personalizedClassifications?.map((classification, index) => (
                          <Typography variant="body2" key={index}>
                            {classification.name}
                          </Typography>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>

            {(isFillCanvas(ideaDetails.canvas) || isFillCanvas(ideaDetails.canvasValueProposition)) && (
            <Grid item xs={12}>
              <Card elevation={0} className={classes.cardSmall}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Avatar className={classes.avatarIcon}>
                        <DashboardRoundedIcon color="primary" fontSize="small" />
                      </Avatar>
                      <Typography variant="subtitle1">
                        {t('canvas')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {isFillCanvas(ideaDetails.canvasValueProposition) && (
                      <Button
                        onClick={handleOnOpenValueCanvas}
                        className={classes.buttonCanvas}
                        startIcon={<ValuePropositionCanvasIcon style={{ fill: theme.palette.neutrals.low.dark }} />}
                      >
                        Value Proposition Canvas
                      </Button>
                    )}
                    {isFillCanvas(ideaDetails.canvas) && (
                      <Button
                        onClick={handleOnOpenCanvas}
                        className={classes.buttonCanvas}
                        startIcon={<DashboardOutlinedIcon style={{ fontSize: 24 }} />}
                      >
                        Business Model Canvas
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            )}

            {ideaDetails.contributors?.length > 0 && (
              <Grid item xs={12}>
                <Card elevation={0} className={classes.cardSmall}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatarIcon}>
                          <GroupIcon color="primary" fontSize="small" />
                        </Avatar>
                        <Typography variant="subtitle1">
                          {t('participantes')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {ideaDetails.contributors?.length > 0 && ideaDetails.contributors.map((contributor, index) => (
                        <Box display="flex" alignItems="center" marginBottom="1rem" key={index}>
                          {contributor.attachment?.url ? (
                            <Avatar
                              className={classes.contributorImage}
                              src={contributor.attachment?.url}
                            />
                          ) : (
                            <Avatar
                              className={classes.contributorImage}
                              style={{ backgroundColor: theme.palette.primary.light }}
                            >
                              <PersonIcon style={{ color: theme.palette.primary.main }} />
                            </Avatar>
                          )}
                          <Typography
                            variant="subtitle2"
                            className={classes.contributorName}
                            onClick={() => history.push(`/profile/${contributor._id}`)}
                          >
                            {contributor.name}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        
        {(ideaDetails.links?.length > 0 || ideaDetails.attachments?.length > 0) && (
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Avatar className={classes.avatarIcon}>
                      <AttachmentIcon color="primary" fontSize="small" />
                    </Avatar>
                    <Typography variant="subtitle1">
                      {t('anexos')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography 
                    variant="body2" 
                    style={{ fontWeight: 500, color: theme.palette.auxiliary.indigo.main }}
                  >
                    {ideaDetails.links?.length > 0 && (
                      <ImagineLinkify>{ideaDetails.links}</ImagineLinkify>
                    )}
                  </Typography>
                  {ideaDetails.attachments?.length > 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <AttachmentFiles attachments={ideaDetails.attachments} />
                        <AttachmentVideo attachments={ideaDetails.attachments} />
                        <AttachmentImages attachments={ideaDetails.attachments} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {ideaDetails?.businessUnitLocal?.length > 0 && (
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Avatar className={classes.avatarIcon}>
                      <InfraStructureIcon style={{ fill: theme.palette.primary.main }} />
                    </Avatar>
                    <Typography variant="subtitle1">
                      {t('abrangencia')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('unidade_de_negocio')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('local')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('area_de_atuacao')}</Typography>
                    </Grid>
                  </Grid>
                  {ideaDetails.businessUnitLocal.map((businessUnitLocal, index) => (
                    <Grid container spacing={4} key={index} display="flex" alignItems="center">
                      <Grid item xs={4}>
                        <Typography variant="body2">{businessUnitLocal?.businessUnit?.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">{businessUnitLocal?.local?.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">{businessUnitLocal?.occupationArea?.name}</Typography>
                      </Grid>
                      {index !== (ideaDetails.businessUnitLocal.length - 1) && ( 
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                          <Divider />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
         
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.titleUpperCase}>
              {t('qualificadores_atribuidos')}
            </Typography>
            <MenuInformation>
              {t('possiveis_qualificadores_para_essa_ideia_sao_os_gestores_da_area_de_abrangencia_vinculada')}
            </MenuInformation>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <IdeaApproverTable />
        </Grid>

        {(user.typeProfile.type === ('admin' || 'manager') && ideaDetails?.statusIdea?.code !== "AWAITING_QUALIFICATION") && (
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                label={t('exibir_ideia_no_feed')}
                control={
                  <Switch
                    color="primary"
                    checked={ideaDetails?.timeline}
                    onChange={() => handleChange(!ideaDetails?.timeline)}
                  />
                }
              />
            </FormGroup>
          </Grid>
        )}

      </Grid>
    </>
  );
}

export { IdeaGeneral };
